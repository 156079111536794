module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@6.2.0_gatsby-plugin-sharp@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslin_yaxk5ioamlkoi5vtq54gqzqyaq/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"withWebp":true,"quality":100,"showCaptions":true,"linkImagesToOriginal":false,"backgroundColor":"transparent","markdownCaptions":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.2.0_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3._armpouvsg5gw73lqtv3eex4bxm/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"واثق الشويطر","short_name":"واثق الشويطر","start_url":"/","background_color":"#6574CD","theme_color":"#6574CD","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aec5faa714d51fe37c790b4763c69557"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@3.2.0_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1__vyt2fe45cchrzl4srxmgmx3uv4/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layouts/index.jsx"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-social9-socialshare@1.0.6_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__reac_lsn7tccdkuqmoz25opvuvcxuzm/node_modules/gatsby-plugin-social9-socialshare/gatsby-browser.js'),
      options: {"plugins":[],"content":"7cc967e1d1c24940982b0d2a660a9c5c","async":true,"defer":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@5.25.0_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3._ojkrdgjmpoglalfa62u4ck3je4/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-simple-analytics@1.0.4/node_modules/gatsby-plugin-simple-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackPageViews":true,"events":true,"eventsGlobal":"sa_event","ignorePages":["pathname"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__gylmov42njjaynhsb5aemmkeqq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
