import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BigNews from '../BigNews'

const Footer = () => {
  return (
    <>
      <footer className="bg-gray-700 text-white px-3 py-6">
        <div className="container max-w-5xl ">
          <div className="flex flex-col md:flex-row justify-between py-4">
            <p className="m-0 ml-4 w-full md:w-1/2">
              يتناول هذا الموقع تدويناتي التقنية وغيرها وسيرتي المهنية ويعد
              باختصار: نافذتي على عالم الويب. القالب المستخدم معرّب ومتفرع عن{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://github.com/guillaumebriday/guillaumebriday.fr"
                className="footer-link relative text-gray-300 hover:text-gray-100 hover:no-underline w-1/3"
              >
                هذا القالب.
              </a>
            </p>
            <div className="flex-shrink-0 flex flex-wrap md:w-1/3 mt-4 md:mt-0">
              <span className="w-1/3 mb-2">
                <Link
                  className="footer-link relative text-gray-300 hover:text-gray-100 hover:no-underline"
                  to="/"
                >
                  الرئيسية
                </Link>
              </span>

              <span className="w-1/3 mb-2">
                <Link
                  className="footer-link relative text-gray-300 hover:text-gray-100 hover:no-underline w-1/3"
                  to="/uses"
                >
                  ماذا أستخدم؟
                </Link>
              </span>

              <span className="w-1/3 mb-2">
                <Link
                  className="footer-link relative text-gray-300 hover:text-gray-100 hover:no-underline w-1/3"
                  to="/blog"
                >
                  المدونة
                </Link>
              </span>

              <span className="w-1/3 mb-2">
                <Link
                  className="footer-link relative text-gray-300 hover:text-gray-100 hover:no-underline w-1/3"
                  to="/categories"
                >
                  الفئات
                </Link>
              </span>

              {/**
              <span className="w-1/3 mb-2">
                <Link
                  className="footer-link relative text-gray-300 hover:text-gray-100 hover:no-underline w-1/3"
                  to="/mon-profil"
                >
                  ملفي الشخصي
                </Link>
              </span>
               */}
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-baseline border-t pt-4">
            <p className="m-0 ml-4 mb-3 w-full md:w-1/2">
              محتوى المدونة مرخص بموجب{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://creativecommons.org/licenses/by-sa/4.0/deed.ar"
                className="footer-link relative text-gray-300 hover:text-gray-100 hover:no-underline w-1/3"
              >
                رخصة المشاع الإبداعي نَسب المُصنَّف - الترخيص بالمثل 4.0 دولي.
              </a>
            </p>
            <ul className=" mt-2 flex justify-between w-full md:w-1/3 text-2xl">
              <li>
                <a
                  className="text-gray-300 hover:text-gray-100"
                  target="_blank"
                  rel="noreferrer"
                  href="/feed.xml"
                  title="RSS"
                >
                  <FontAwesomeIcon icon="rss" />
                </a>
              </li>

              <li>
                <a
                  className="text-gray-300 hover:text-gray-100"
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/WatheqAlshowaiter"
                  title="GitHub"
                >
                  <FontAwesomeIcon icon={['fab', 'github']} />
                </a>
              </li>

              <li>
                <a
                  className="text-gray-300 hover:text-gray-100"
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/watheq_show"
                  title="Twitter"
                >
                  <FontAwesomeIcon icon={['fab', 'twitter']} />
                </a>
              </li>

              <li>
                <a
                  className="text-gray-300 hover:text-gray-100"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UC-iPcPdHqihpNpOwnHCD-mg"
                  title="YouTube"
                >
                  <FontAwesomeIcon icon={['fab', 'youtube']} />
                </a>
              </li>

              <li>
                <a
                  className="text-gray-300 hover:text-gray-100"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/watheq-show"
                  title="Linkedin"
                >
                  <FontAwesomeIcon icon={['fab', 'linkedin']} />
                </a>
              </li>

              <li>
                <a
                  className="text-gray-300 hover:text-gray-100"
                  target="_blank"
                  rel="noreferrer"
                  href="https://xyz.us19.list-manage.com/subscribe/post?u=e89e9cac4b887c5ca51a7bfa8&amp;id=d4d17540ed"
                  title="القائمة البريدية"
                >
                  <FontAwesomeIcon icon={['fas', 'envelope']} />
                </a>
              </li>

              <li>
                <a
                  className="text-gray-300 hover:text-gray-100"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/watheq.show/"
                  title="facebook"
                >
                  <FontAwesomeIcon icon={['fab', 'facebook']} />
                </a>
              </li>

              <li>
                <a
                  className="text-gray-300 hover:text-gray-100"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.buymeacoffee.com/watheq"
                  title="اعزمني على قهوة"
                >
                  <FontAwesomeIcon icon="mug-hot" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <BigNews />
    </>
  )
}

export default Footer
