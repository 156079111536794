import React from 'react'
import { Link } from 'gatsby'

const Header = ({ isBlog }) => {
  const activeClassName = (isActive) => {
    let classes =
      'header-link flex-shrink-0 hover:no-underline hover:text-indigo-500 relative mr-8 my-2 lg:my-0 font-semibold'
    let activeClasses = isActive ? 'active text-indigo-500' : 'text-gray-900'

    return { className: [classes, activeClasses].join(' ') }
  }

  const classNameBlog = ({ isPartiallyCurrent }) =>
    activeClassName(isBlog || isPartiallyCurrent)

  return (
    <header className="px-3 shadow">
      <nav className="container max-w-6xl text-gray">
        <div
          className="-my-2px py-4 flex flex-col lg:flex-row flex-wrap justify-between items-baseline"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Link
            className="mr-4 text-2xl text-gray-500 hover:no-underline flex-shrink-0 font-normal"
            to="/"
          >
            واثق <span className="font-extrabold text-black">الشويطر</span>
          </Link>

          <div
            className="flex flex-col md:flex-row items-baseline w-full md:w-auto"
            style={{ display: 'contents' }}
          >
            <Link getProps={classNameBlog} to="/blog">
              المدونة
            </Link>
            {/**
            <Link getProps={className} to="/mon-profil">
              ملفي الشخصي
            </Link>
             */}
            {/**  prviios search dev on Algolia, useful to reuse it for another wat to seach
              <div className="flex items-center border border-indigo-500 rounded-full mt-2 lg:mt-0 py-1 px-2 pr-4 leading-tight w-full">
              <InstantSearch indexName="blog" searchClient={searchClient}>
                <Index indexName="blog">
                  <Configure hitsPerPage={5} />
                  <AutoComplete />
                </Index>
              </InstantSearch>

              <FontAwesomeIcon icon="search" className="text-indigo-500" />
            </div>
            */}
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
